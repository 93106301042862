import React, { FC, memo } from 'react'
import { Box } from '@ucheba/ui/components/Box/desktop'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { CodeTextField } from '@ucheba/ui/components/CodeTextField/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { ErrorMessage } from 'formik'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import Link from 'next/link'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { ELoading } from '@ucheba/store/utils/response/types'
import { ErrorText } from '@ucheba/ui/components/ErrorText/desktop'
import styled from 'styled-components'
import { useApplicationFormCodeCore } from '../bll'
import content from '../data.json'

const ButtonSC = styled(Button)`
  padding: 0;
  height: auto;
`

/** Ввод кода для подтверждения телефона */
const ApplicationFormRequestCode: FC = () => {
  const {
    phone,
    requestStep,
    onInputCode,
    clientRequestLoading,
    timeLeft,
    isTimeLeft,
    getNewCode,
  } = useApplicationFormCodeCore()

  return (
    <Box color='white' spacing='null'>
      <Heading h1={content.code.title} spacing='small' textAlign='center' />

      <Text textAlign='center'>{`${content.code.phone.prevText} ${phone}`}</Text>

      <Text textAlign='center' html={false}>
        <Link href={requestStep}>{content.code.phone.changeLinkText}</Link>
      </Text>

      <Spacing spacing='xlarge' />

      <Grid justifyContent='center'>
        <GridCol width='auto'>
          <CodeTextField
            onChange={onInputCode}
            disabled={clientRequestLoading === ELoading.pending}
          />
        </GridCol>
      </Grid>

      <ErrorMessage component={ErrorText} textAlign='center' name={EDataKeys.code} />

      <Spacing spacing='xlarge' />

      {isTimeLeft ? (
        <ButtonSC view='link' onClick={getNewCode} block size='small'>
          {content.code.getNewCodeLinkText}
        </ButtonSC>
      ) : (
        <Text textAlign='center' fontSize='small1' color='gray60'>
          {`${content.code.prevTextNewCode} ${timeLeft}`}
        </Text>
      )}
    </Box>
  )
}

export { ApplicationFormRequestCode }
export default memo(ApplicationFormRequestCode)
