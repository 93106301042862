import React, { FC, memo } from 'react'
import { useAuthGuard } from '../bll'

/** Финальный шаг */
const ApplicationFormFinal: FC = (props) => {
  const { children } = props

  useAuthGuard()

  return <>{children}</>
}

export { ApplicationFormFinal }
export default memo(ApplicationFormFinal)
