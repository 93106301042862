import React, { FC, memo } from 'react'
import SC from '../base/Radio'
import { IRadioProps } from '../types'
import { useRadioCore } from '../bll'
import { Text } from '../../Text/desktop'

const { Block, Input, Label, CheckIcon, IconWrapper } = SC

/** Радио кнопка */
const Radio: FC<IRadioProps> = (props) => {
  const {
    label,
    checked,
    firstLetterUppercase,
    disabled = false,
    onChange,
    name,
    submitOnChange,
    fontWeight = 'medium',
    Icon,
    color = 'blue',
    ...otherProps
  } = props

  const { onChangeStatus } = useRadioCore({ name, submitOnChange, onChange })

  return (
    <Block disabled={disabled} color={color}>
      {checked && <CheckIcon disabled={disabled} />}

      <Input
        {...{ checked, ...otherProps }}
        name={name}
        onChange={onChangeStatus}
        disabled={disabled}
        color={color}
      />

      <Label firstLetterUppercase={firstLetterUppercase}>
        {label && (
          <Text
            firstLetterUppercase={firstLetterUppercase}
            fontWeight={fontWeight}
            html={typeof label !== 'object'}
          >
            {label}
          </Text>
        )}
      </Label>

      {Icon && (
        <IconWrapper active={checked}>
          <Icon color='blue' width='20px' height='20px' />
        </IconWrapper>
      )}
    </Block>
  )
}

export { Radio }
export default memo(Radio)
