import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useKeyPress } from '@ucheba/utils/hooks/useKeyPress'
import { IUseCodeTextFieldCore } from './types'

export const useCodeTextFieldCore: IUseCodeTextFieldCore = ({ onChange }) => {
  const initialValues = {
    0: '',
    1: '',
    2: '',
    3: '',
  }
  const [codeValues, setCodeValues] = useState(initialValues)

  const [fieldFocused, setFieldFocused] = useState(0)

  const refFirst = useRef<HTMLInputElement>(null)
  const refSecond = useRef<HTMLInputElement>(null)
  const refThird = useRef<HTMLInputElement>(null)
  const refFourth = useRef<HTMLInputElement>(null)
  const refs = useMemo(() => [refFirst, refSecond, refThird, refFourth], [])

  const isArrowLeftPress = useKeyPress('ArrowLeft')
  const isArrowRightPress = useKeyPress('ArrowRight')
  const isBackspacePress = useKeyPress('Backspace')

  const fieldIsFocused = useMemo(() => fieldFocused >= 0, [fieldFocused])

  /** При нажатии на стрелочки, переключать фокус */
  useEffect(() => {
    if (!fieldIsFocused) return

    if (isArrowLeftPress && fieldFocused > 0) {
      setFieldFocused(fieldFocused - 1)
    }

    if (isArrowRightPress && fieldFocused < 3) {
      setFieldFocused(fieldFocused + 1)
    }
  }, [fieldFocused, fieldIsFocused, isArrowLeftPress, isArrowRightPress])

  /** Обрабатываем нажатие на backspace */
  useEffect(() => {
    if (fieldIsFocused && isBackspacePress && !codeValues[fieldFocused]) {
      codeValues[fieldFocused - 1] = ''
      setCodeValues(codeValues)
      setFieldFocused(fieldFocused - 1)
    }
  }, [isBackspacePress, refs])

  /** Проставляем фокус на нужный инпут */
  useEffect(() => refs[fieldFocused]?.current?.focus(), [fieldFocused, refs])

  /** Проставляем состояние фокуса при нативном клике на инпут */
  const handleFocus = (number) => (): void => {
    setFieldFocused(number)
    refs[number]?.current?.select()
  }

  /** Проставляем состояние фокуса при нативном анфокусе инпута */
  const handleBlur = useCallback((): void => {
    setFieldFocused(-1)
  }, [])

  /** Проставляем состояние фокуса при изменении инпута */
  const handleChange =
    (number) =>
    (event, value): void => {
      codeValues[number] = value
      setCodeValues(codeValues)

      const preparedCode = Object.keys(codeValues).reduce(
        (acc, item) => `${acc}${codeValues[item]}`,
        ''
      )

      if (onChange) onChange(event, preparedCode)

      if (value && number !== 3) {
        setFieldFocused(number + 1)
      }
    }

  /** При вставке в любой инпут строки из 4 цифр, распределяем их между всеми инпутами */
  const handleChangeWithoutValid = useCallback(
    (_, value) => {
      const cleanValue = value ? value.trim() : ''

      if (cleanValue.length === 4 && /^\d+$/g.test(cleanValue)) {
        const newValues = cleanValue.split('')

        setCodeValues({
          0: newValues[0],
          1: newValues[1],
          2: newValues[2],
          3: newValues[3],
        })

        if (onChange) onChange(new Event('change', { bubbles: true }), cleanValue)
      }
    },
    [onChange]
  )

  /** Возвращает значение инпута по индексу */
  const getValue = useCallback((number): string => codeValues[number], [codeValues])

  return {
    refs,
    handleFocus,
    handleBlur,
    handleChange,
    handleChangeWithoutValid,
    getValue,
  }
}
