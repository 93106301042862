import { useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { authSelectors } from '@ucheba/store/auth'
import { clientProductsRequestsSelectors } from '@ucheba/store/client-products/requests'
import { ELoading } from '@ucheba/store/utils/response/types'
import { EApplicationFormQuery } from '../types'

interface IUseAutoRequest {
  (): {
    isAutoReqLoading: boolean
  }
}

/* автоматический самбит формы */
export const useAutoRequest: IUseAutoRequest = () => {
  const { submitForm } = useFormikContext()
  const isAuth = useSelector(authSelectors.isAuth)
  const clientLoading = useSelector(clientProductsRequestsSelectors.loading)
  const router = useRouter()
  const { query } = router

  useEffect(() => {
    if (isAuth && query[EApplicationFormQuery.autoReq]) {
      setTimeout(() => {
        submitForm()
      }, 2000)
    }
  }, [])

  const isAutoReqLoading = useMemo(
    () =>
      !!(
        query[EApplicationFormQuery.autoReq] &&
        isAuth &&
        clientLoading !== ELoading.rejected
      ),
    [query, isAuth, clientLoading]
  )

  return {
    isAutoReqLoading,
  }
}
