import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'

const InnerContainer = styled.div`
  margin-bottom: ${sv.spacing.large};
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default { Block, InnerContainer }
