import React, { FC, memo } from 'react'
import { Text } from '../../Text/desktop'
import { Spacing } from '../../Spacing/desktop'
import { IErrorTextProps } from '../types'

const ErrorText: FC<IErrorTextProps> = (props) => {
  const { children, ...otherProps } = props

  if (children) {
    return (
      <>
        <Spacing spacing='usmall' />

        <Text
          textAlign='left'
          color='red'
          fontSize='small1'
          lineHeight='small1'
          {...otherProps}
        >
          {children}
        </Text>
      </>
    )
  }

  return null
}

export { ErrorText }
export default memo(ErrorText)
