import React, { FC, memo, useMemo } from 'react'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Box } from '@ucheba/ui/components/Box/desktop'
import { Field } from 'formik'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { TextField } from '@ucheba/ui/components/TextField/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import { Radio } from '@ucheba/ui/components/Radio/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { ErrorText } from '@ucheba/ui/components/ErrorText/desktop'
import { useSelector } from 'react-redux'
import { clientProductsRequestsSelectors } from '@ucheba/store/client-products/requests'
import { ELoading } from '@ucheba/store/utils/response/types'
import Loader from '@ucheba/ui/components/Loader/desktop/Loader'
import content from '../data.json'
import { IApplicationFormRequestApplicationProps } from '../types'
import { useAutoRequest } from '../bll/useAutoRequest'

/** Форма отправки заявки */
const ApplicationFormRequestApplication: FC<IApplicationFormRequestApplicationProps> = (
  props
) => {
  const { aboutParent } = props
  const sendLoading = useSelector(clientProductsRequestsSelectors.loading)
  const apiErrors = useSelector(clientProductsRequestsSelectors.error)

  const errors = useMemo((): any => {
    if (apiErrors) {
      return apiErrors.formErrors?.reduce((acc, item) => {
        acc[item.field] = item

        return acc
      }, {})
    }

    return {}
  }, [apiErrors])

  const { isAutoReqLoading } = useAutoRequest()

  if (isAutoReqLoading) {
    return (
      <Box color='white' spacing='usmall'>
        <Text fontSize='h3' fontWeight='bold' lineHeight='h2'>
          Отправка заявки
        </Text>

        <Spacing spacing='xlarge' />

        <Loader />
      </Box>
    )
  }

  return (
    <Box color='white' spacing='usmall'>
      <Heading textAlign='center' h1={content.application.title} spacing='xlarge' />

      <Field
        as={TextField}
        name={EDataKeys.name}
        label={content.application.fields.name}
        required
      />

      {errors[EDataKeys.name] && (
        <ErrorText color='red'>{errors[EDataKeys.name].message}</ErrorText>
      )}

      <Spacing spacing='xlarge' />

      <Field
        as={TextField}
        name={EDataKeys.phone}
        type='tel'
        label={content.application.fields.phone}
        required
      />

      {errors[EDataKeys.phone] && (
        <ErrorText color='red'>{errors[EDataKeys.phone].message}</ErrorText>
      )}

      <Spacing spacing='xlarge' />

      {/* <Field */}
      {/*  as={TextField} */}
      {/*  name={EDataKeys.email} */}
      {/*  type='email' */}
      {/*  label={content.application.fields.email} */}
      {/*  required */}
      {/* /> */}

      {errors[EDataKeys.email] && (
        <ErrorText color='red'>{errors[EDataKeys.email].message}</ErrorText>
      )}

      <Spacing spacing='xlarge' />

      {aboutParent && (
        <>
          <Grid spacing='small'>
            <GridCol width='auto'>
              <Field
                as={Radio}
                type='radio'
                name={EDataKeys.isParent}
                value='false'
                label={content.application.fields.enrollee}
              />
            </GridCol>

            <GridCol width='auto'>
              <Field
                as={Radio}
                type='radio'
                name={EDataKeys.isParent}
                value='true'
                label={content.application.fields.parent}
              />
            </GridCol>
          </Grid>

          {errors[EDataKeys.isParent] && (
            <ErrorText color='red'>{errors[EDataKeys.isParent].message}</ErrorText>
          )}

          <Spacing spacing='xlarge' />
        </>
      )}

      <Field
        as={TextField}
        name={EDataKeys.specialOfferCode}
        label={content.application.fields.promocode}
      />

      {errors[EDataKeys.specialOfferCode] && (
        <ErrorText color='red'>{errors[EDataKeys.specialOfferCode].message}</ErrorText>
      )}

      <Spacing spacing='xlarge' />

      <Button
        block
        type='submit'
        color='orange'
        progress={sendLoading === ELoading.pending}
      >
        {content.application.buttonText}
      </Button>

      <Spacing spacing='large' />

      <Text color='gray60' fontSize='small1' lineHeight='small1' textAlign='center'>
        {content.application.agreementText}
      </Text>
    </Box>
  )
}

export { ApplicationFormRequestApplication }
export default memo(ApplicationFormRequestApplication)
