import React, { FC, useEffect } from 'react'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { ELoading } from '@ucheba/store/utils/response/types'
import { Box } from '@ucheba/ui/components/Box/desktop'
import { useSelector } from 'react-redux'
import { clientProductsRequestsSelectors } from '@ucheba/store/client-products/requests'
import TelegramIcon from '@ucheba/ui/icons/icon__telegram-filled.svg'
import VkIcon from '@ucheba/ui/icons/icon__vk_square.svg'
import WhatsAppIcon from '@ucheba/ui/icons/icon__whatsapp-filled.svg'
import { analyticEvent } from '@ucheba/utils/helpers/analytics/events'
import {
  EAnalyticEvents,
  EAnalyticEventTargets,
  EAnalyticSections,
} from '@ucheba/utils/helpers/analytics/events/types'
import content from '../data.json'
import { useMessengersLinks } from '../bll'
import { IApplicationFormMessengers } from '../types'

const ApplicationFormMessengers: FC<IApplicationFormMessengers> = (props) => {
  const { tgHref, vkHref, waHref, messages = ['vk', 'tg', 'wa'], productId } = props
  const sendLoading = useSelector(clientProductsRequestsSelectors.loading)

  const { tg, vk, wa } = useMessengersLinks(productId)

  // отправка цели в ym об открытии поп-апа с мессенджерами
  useEffect(() => {
    analyticEvent({
      targets: [
        EAnalyticEventTargets.ym,
        EAnalyticEventTargets.ur,
        EAnalyticEventTargets.gtm,
      ],
      eventName: EAnalyticEvents.requestMessengersFormOpened,
      section: EAnalyticSections.requestForm,
    })
  }, [])

  return (
    <>
      <Box color='white' spacing='usmall'>
        <Heading h2={content.messengers.title} spacing='small' />

        <Text fontSize='large'>{content.messengers.requestText}</Text>

        {messages.includes('tg') && (
          <>
            <Spacing spacing='large' />

            <Button
              block
              type='submit'
              color='orange'
              progress={sendLoading === ELoading.pending}
              StartIcon={<TelegramIcon width='28px' height='28px' />}
              href={tgHref || tg}
              onClick={() => {
                analyticEvent({
                  targets: [
                    EAnalyticEventTargets.ym,
                    EAnalyticEventTargets.ur,
                    EAnalyticEventTargets.gtm,
                  ],
                  eventName: EAnalyticEvents.requestToTG,
                  section: EAnalyticSections.requestForm,
                })
              }}
              target='_blank'
            >
              {content.messengers.telegramButtonText}
            </Button>
          </>
        )}

        {messages.includes('wa') && (
          <>
            <Spacing spacing='small' />

            <Button
              block
              type='submit'
              color='orange'
              progress={sendLoading === ELoading.pending}
              StartIcon={<WhatsAppIcon width='24px' height='24px' />}
              href={waHref || wa}
              onClick={() => {
                analyticEvent({
                  targets: [
                    EAnalyticEventTargets.ym,
                    EAnalyticEventTargets.ur,
                    EAnalyticEventTargets.gtm,
                  ],
                  eventName: EAnalyticEvents.requestToWhatsApp,
                  section: EAnalyticSections.requestForm,
                })
              }}
              target='_blank'
            >
              {content.messengers.whatsAppButtonText}
            </Button>
          </>
        )}

        {messages.includes('vk') && (
          <>
            <Spacing spacing='small' />

            <Button
              block
              type='submit'
              color='orange'
              progress={sendLoading === ELoading.pending}
              StartIcon={<VkIcon width='24px' height='24px' />}
              href={vkHref || vk}
              onClick={() => {
                analyticEvent({
                  targets: [
                    EAnalyticEventTargets.ym,
                    EAnalyticEventTargets.ur,
                    EAnalyticEventTargets.gtm,
                  ],
                  eventName: EAnalyticEvents.requestToVK,
                  section: EAnalyticSections.requestForm,
                })
              }}
              target='_blank'
            >
              {content.messengers.vkButtonText}
            </Button>
          </>
        )}
      </Box>
    </>
  )
}

export default ApplicationFormMessengers
