import React, { FC, memo, useEffect } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'

import { Dialog } from '@ucheba/ui/components/Dialog/desktop'
import { Box } from '@ucheba/ui/components/Box/desktop'
import { analyticEvent } from '@ucheba/utils/helpers/analytics/events'
import {
  EAnalyticEvents,
  EAnalyticEventTargets,
  EAnalyticSections,
} from '@ucheba/utils/helpers/analytics/events/types'
import SC from '../base/ApplicationForm.styles'
import { EApplicationFormSteps, IApplicationFormProps } from '../types'
import ApplicationFormParent from './ApplicationFormParent'
import { ApplicationFormFinal } from './ApplicationFormFinal'
import { ApplicationFormRequest } from './ApplicationFormRequest'
import { useRequestTags, useStepName } from '../bll'
import ApplicationFormMessengers from './ApplicationFormMessengers'

const { Block: BlockSC, InnerContainer } = SC

const Block = styled(BlockSC)``

/** Форма отправки заявки */

const ApplicationForm: FC<IApplicationFormProps> = (props) => {
  const {
    productId,
    productType,
    count,
    funnel,
    requestTarget,
    subjectId,
    eventsId,
    referralCode,
    specialOfferCode,
    children,
    open,
    close,
    isDialog = true,
    aboutParent = true,
    withMessengers = true,
    messengersForm = false,
    tgHref,
    vkHref,
    waHref,
    messages,
  } = props

  const { pathname } = useRouter()

  // некрасивый способ изменить текст под один кейс
  const isEgeQuest = pathname.includes('ege/quest')

  // отправка цели в YM об открытии поп-апа формы
  useEffect(() => {
    if (open) {
      analyticEvent({
        targets: [
          EAnalyticEventTargets.ym,
          EAnalyticEventTargets.ur,
          EAnalyticEventTargets.gtm,
        ],
        eventName: EAnalyticEvents.requestPopupOpened,
        section: EAnalyticSections.requestForm,
      })
    }
  }, [open])

  const stepName = useStepName()

  const { isMessengersForm } = useRequestTags()

  const StepElement = (() => {
    if (isMessengersForm || messengersForm) {
      return (
        <ApplicationFormMessengers
          isEgeQuest={isEgeQuest}
          tgHref={tgHref}
          vkHref={vkHref}
          messages={messages}
          waHref={waHref}
          productId={productId}
        />
      )
    }

    switch (stepName) {
      case EApplicationFormSteps.messengers:
        return (
          <ApplicationFormMessengers
            isEgeQuest={isEgeQuest}
            tgHref={tgHref}
            vkHref={vkHref}
            waHref={waHref}
            messages={messages}
            productId={productId}
          />
        )

      case EApplicationFormSteps.parent:
        return <ApplicationFormParent />

      default:
        return (
          <ApplicationFormRequest
            count={count}
            productId={productId}
            productType={productType}
            funnel={funnel}
            requestTarget={requestTarget}
            subjectId={subjectId}
            eventsId={eventsId}
            referralCode={referralCode}
            aboutParent={aboutParent}
            specialOfferCode={specialOfferCode}
          />
        )
    }
  })()

  if (!isDialog) {
    return (
      <Box color='white' spacing='null'>
        {stepName === EApplicationFormSteps.final ? (
          <ApplicationFormFinal>
            <InnerContainer>
              {withMessengers ? (
                <ApplicationFormMessengers
                  isEgeQuest={isEgeQuest}
                  tgHref={tgHref}
                  vkHref={vkHref}
                  waHref={waHref}
                  messages={messages}
                  productId={productId}
                />
              ) : (
                children
              )}
            </InnerContainer>
          </ApplicationFormFinal>
        ) : (
          <InnerContainer>{StepElement}</InnerContainer>
        )}
      </Box>
    )
  }

  if (!open) return null

  return (
    <Dialog open={open} onClose={close} overflow='auto' spacing='xlarge' size='small'>
      <Block>
        {stepName === EApplicationFormSteps.final ? (
          <ApplicationFormFinal>
            <InnerContainer>
              {withMessengers ? (
                <ApplicationFormMessengers
                  isEgeQuest={isEgeQuest}
                  tgHref={tgHref}
                  waHref={waHref}
                  vkHref={vkHref}
                  messages={messages}
                />
              ) : (
                children
              )}
            </InnerContainer>
          </ApplicationFormFinal>
        ) : (
          <InnerContainer>{StepElement}</InnerContainer>
        )}
      </Block>
    </Dialog>
  )
}

export { ApplicationForm }
export default memo(ApplicationForm)
