import styled, { StyledProps } from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { mathVars, sm } from '@ucheba/utils/helpers/styles/mixins'
import {
  EModDisabled,
  EModFirstLetterUppercase,
  IModDisabled,
  IModFirstLetterUppercase,
} from '@ucheba/utils/helpers/styles/mod.types'
import { ECheckboxColor } from '../../Checkbox/types'
import { ERadioColorHover } from '../types'

const CheckIcon = styled.div<StyledProps<any>>`
  width: 8px;
  height: 8px;
  background-color: ${sv.color.white};
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 6px;
  z-index: 1;
`

const Label = styled.div<StyledProps<IModFirstLetterUppercase>>`
  display: inline-block;
  user-select: none;
  margin-left: ${mathVars(sv.spacing.xsmall, '+', '20px')};
  transition: ${sv.duration.fast};

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 0;
    flex-shrink: 0;
    flex-grow: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px ${sv.color.gray15};
    transition: ${sv.duration.fast};
  }

  ${sm.mod(EModFirstLetterUppercase.true)`
    &::first-letter {
      text-transform: uppercase;
    }
  `};
`

interface IInput {
  color: keyof typeof ECheckboxColor
}

const Input = styled.input<StyledProps<IInput>>`
  display: none;

  &:checked + ${Label}:before {
    background-color: ${(props) => sv.color[props.color]};
    box-shadow: inset 0 0 0 1px ${(props) => sv.color[props.color]};

    ${sm.mod(EModDisabled.true)`
      background-color: ${sv.color.gray5};
      box-shadow: inset 0 0 0 1px ${sv.color.gray5};
    `}
  }
`

const Block = styled.label<StyledProps<IModDisabled & IInput>>`
  display: block;
  cursor: pointer;
  position: relative;

  & ${Label} {
    ${sm.mod(EModDisabled.true)`
      color: ${sv.color.gray25};
    `}
  }

  & ${CheckIcon} {
    ${sm.mod(EModDisabled.true)`
      background-color: ${sv.color.gray25};
    `}
  }
  &:hover {
    ${Input} {
      & + ${Label}:before {
        box-shadow: inset 0 0 0 1px ${(props) => sv.color[ERadioColorHover[props.color]]};
      }

      &:checked + ${Label}:before {
        background-color: ${(props) => sv.color[ERadioColorHover[props.color]]};
      }
      &:disabled + ${Label}:before {
        background-color: inherit;
        box-shadow: inset 0 0 0 1px ${sv.color.gray15};
      }
    }

    ${Label} {
      color: ${(props) => sv.color[ERadioColorHover[props.color]]};
      ${sm.mod(EModDisabled.true)`
      color: ${sv.color.gray25};
      `}
    }
  }
`

interface IIconWrapperProps {
  active?: boolean
}

const IconWrapper = styled.div<StyledProps<IIconWrapperProps>>`
  width: 20px;
  height: 20px;
  margin-right: 0;
  margin-left: auto;
  position: absolute;
  top: 0;
  right: 0;

  svg {
    color: ${(props) => (props.active ? sv.color.blue : sv.color.gray15)};
  }
`

export default { Block, Input, Label, CheckIcon, IconWrapper }
