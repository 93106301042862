import {
  IModChecked,
  IModDisabled,
  IModFirstLetterUppercase,
  IModFontWeight,
} from '@ucheba/utils/helpers/styles/mod.types'
import {
  IPropLabel,
  IPropName,
  IPropSubmitOnChange,
} from '@ucheba/utils/helpers/components/prop.types'
import { ChangeEvent, HTMLAttributes } from 'react'

export interface IRadioProps
  extends IPropLabel,
    IModFontWeight,
    IPropName,
    IPropSubmitOnChange,
    IModFirstLetterUppercase,
    IModDisabled,
    IModChecked,
    Pick<HTMLAttributes<HTMLInputElement>, 'onChange'> {
  /** Иконка после текста  */
  Icon?: SVGElement

  color?: keyof typeof ERadioColor
}

export interface IUseRadioCore {
  (props: Pick<IRadioProps, 'name' | 'onChange' | 'submitOnChange'>): {
    onChangeStatus(event: ChangeEvent<HTMLInputElement>): void
  }
}

export enum ERadioColor {
  plum = 'plum',
  blue = 'blue',
}

export enum ERadioColorHover {
  plum = 'plum',
  blue = 'cobalt',
}
