import React, { FC, memo } from 'react'
import { Box } from '@ucheba/ui/components/Box/desktop'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { useSelector } from 'react-redux'
import { TextField } from '@ucheba/ui/components/TextField/desktop'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { ErrorText } from '@ucheba/ui/components/ErrorText/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { ErrorMessage, Field, Formik } from 'formik'
import { profileSelectors } from '@ucheba/store/profile'
import { Form } from '@ucheba/ui/components/Form/desktop'
import { useApplicationFormParentCore, useAuthGuard } from '../bll'
import content from '../data.json'

/** Форма отправки данных родителя */
const ApplicationFormParent: FC = () => {
  const apiErrors = useSelector(profileSelectors.error)
  const { initialValues, onSubmit, isLoading } = useApplicationFormParentCore()

  useAuthGuard()

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      <Form apiErrors={apiErrors} fill fillByProfile>
        <Box color='white' spacing='null'>
          <Heading h1={content.parent.title} spacing='small' textAlign='center' />

          <Text textAlign='center'>{content.parent.hint}</Text>

          <Spacing spacing='xlarge' />

          <Field
            as={TextField}
            name={EDataKeys.parentName}
            label={content.parent.fields.name}
            required
          />

          <ErrorMessage component={ErrorText} name={EDataKeys.parentName} />

          <Spacing spacing='xlarge' />

          <Field
            as={TextField}
            name={EDataKeys.parentPhone}
            type='tel'
            label={content.parent.fields.phone}
            required
          />

          <ErrorMessage component={ErrorText} name={EDataKeys.parentPhone} />

          <Spacing spacing='xlarge' />

          <Button block type='submit' color='orange' progress={isLoading}>
            {content.parent.buttonText}
          </Button>
        </Box>
      </Form>
    </Formik>
  )
}

export { ApplicationFormParent }
export default memo(ApplicationFormParent)
