import { useCallback } from 'react'
import { useFormikContext } from 'formik'
import { IUseRadioCore } from './types'

export const useRadioCore: IUseRadioCore = (props) => {
  const { onChange, name, submitOnChange } = props

  const { setFieldValue, submitForm } = useFormikContext()

  const onChangeStatus = useCallback(
    (event) => {
      if (name) setFieldValue(name, event.target.value)

      if (onChange) onChange(event)

      if (submitOnChange) submitForm()
    },
    [name, onChange, setFieldValue, submitForm, submitOnChange]
  )

  return {
    onChangeStatus,
  }
}
