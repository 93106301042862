import React, { FC, memo } from 'react'
import styled from 'styled-components'
import { TextField } from '../../TextField/desktop'
import { ICodeTextFieldProps } from '../types'
import { Grid, GridCol } from '../../Grid/desktop'
import { useCodeTextFieldCore } from '../bll'

const TextFieldST = styled(TextField)`
  text-align: center;
`

/* Текстовое поле для кода */
const CodeTextField: FC<ICodeTextFieldProps> = (props) => {
  const { onChange, ...otherProps } = props
  const {
    refs,
    handleFocus,
    handleChange,
    handleBlur,
    handleChangeWithoutValid,
    getValue,
  } = useCodeTextFieldCore({ onChange })

  return (
    <Grid spacing='small'>
      {refs.map((ref, index) => (
        <GridCol width='63px' key={index}>
          <TextFieldST
            {...otherProps}
            ref={ref}
            // костыль чтобы валидация и ограничение работали в safari
            type='text'
            pattern='[0-9]*'
            min={0}
            max={9}
            value={getValue(index) || ''}
            onChange={handleChange(index)}
            onFocus={handleFocus(index)}
            onBlur={handleBlur}
            onChangeWithoutValid={handleChangeWithoutValid}
          />
        </GridCol>
      ))}
    </Grid>
  )
}

export { CodeTextField }
export default memo(CodeTextField)
